<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
            <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='用户姓名' prop='userid' :label-width="formLabelWidth">
                <el-select v-model="form.userid" placeholder="请选择" filterable>
                    <el-option
                            v-for="item in userList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='信用卡头' :label-width="formLabelWidth">
              <el-input placeholder='请输入信用卡头' clearable autocomplete="off" v-model='form.cardheader' size="small"  />
            </el-form-item>
            <el-form-item label='ip地址' :label-width="formLabelWidth">
              <el-input placeholder='请输入ip地址' clearable autocomplete="off" v-model='form.ip' size="small"  />
            </el-form-item>
            <el-form-item label='名单类别' prop='type' :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择" filterable @change="changeType">
                    <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
          <el-form-item label='风控内容' prop='contentid' :label-width="formLabelWidth" v-if="this.form.type!='1'">
              <el-select v-model="form.contentid" placeholder="请选择" @change="changeContent">
                  <el-option
                          v-for="item in contentList"
                          :key="item.id"
                          :label="item.riskname+'-----'+item.content"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <!--            <el-form-item label='风控等级描述' prop='remark' :label-width="formLabelWidth">-->
          <!--              <el-input placeholder='请输入风控等级描述' clearable autocomplete="off" v-model='form.remark' size="small"  />-->
          <!--            </el-form-item>-->
            <el-form-item label='是否有效' prop='status' :label-width="formLabelWidth">
              <el-select v-model="form.status" placeholder="请选择" filterable>
                  <el-option
                          v-for="item in statusOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='结束时间' prop='endtime' :label-width="formLabelWidth">
                <el-date-picker
                        v-model="form.endtime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertRisklist,updateRisklist,deleteRisklist,bacthDelRisklist,getRiskcontentAll,getUserAll } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const typeOptions=[
          {
              value:"1",
              label:"白名单"
          },
          {
              value:"2",
              label:"灰名单"
          },
          {
              value:"3",
              label:"黑名单"
          },
      ]
      const statusOptions=[
            {
                value:"1",
                label:"有效"
            },
            {
                value:"0",
                label:"无效"
            },

      ]
      const columns = [
          // {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'userid',label: '用户id'},
          {prop: 'cardheader',label: '信用卡头'},
          {prop: 'ip',label: 'ip地址'},
          {prop: 'contentid',label: '风控内容id'},
          {prop: 'remark',label: '风控等级描述'},
          {prop: 'type',label: '名单类别',formatter: row => this.typeState(row)},
          {prop: 'status',label: '是否有效',formatter: row => this.statusState(row)},
          {prop: 'endtime',label: '结束时间(默认永久)'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'cardheader',
            label: '信用卡头',
            el: {placeholder: '请输入信用卡头', clearable: true},
          },
          {
            type: 'input',
            id: 'ip',
            label: 'ip地址',
            el: {placeholder: '请输入ip地址', clearable: true},
          },
          {
            type: 'input',
            id: 'contentid',
            label: '风控内容id',
            el: {placeholder: '请输入风控内容id', clearable: true},
          },
          {
            type: 'input',
            id: 'remark',
            label: '风控等级描述',
            el: {placeholder: '请输入风控等级描述', clearable: true},
          },
          {
            type: 'select',
            id: 'type',
            label: '名单类别',
            el: {placeholder: '请输入名单类别', clearable: true},
            options:typeOptions
          },
      ]
      return {
        columns,
        typeOptions,
        statusOptions,
        userinfo:{},
        contentList:[],
        userList:[],
        tableConfig: {
          searchForm,
          url: 'risklist/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('risklist:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('risklist:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('risklist:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            // {
            //   text: '批量删除',
            //   type: 'primary',
            //   show:()=>{return this.hasRole('risklist:batchDelete')},
            //   disabled: selected => selected.length < 1,
            //   atClick: selected => {
            //     let ids = selected.map(item => item.id)
            //     this.Batch('delete', ids)
            //     return false
            //   },
            // },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"110px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            cardheader :[
              {required: true, message: '请输入信用卡头', trigger: 'blur'},],
            ip :[
              {required: true, message: '请输入ip地址', trigger: 'blur'},],
            contentid :[
              {required: true, message: '请输入风控内容id', trigger: 'blur'},],
            remark :[
              {required: true, message: '请输入风控等级描述', trigger: 'blur'},],
            status:[
                {required: true, message: '请输入是否有效', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入名单类别', trigger: 'blur'},],
            endtime :[
              {required: true, message: '请输入结束时间(默认永久)', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
      changeType(){
        if (this.form.type=='1'){
            this.form.contentid=''
        }
      },
      changeContent(){
        this.contentList.forEach(r=>{
            if (r.id==this.form.contentid){
                this.form.remark=r.content
            }
        })
      },
      typeState(row){
            let data
            this.typeOptions.forEach(r=>{
                if (r.value==row.type){
                    data=r.label
                }
            })
            return data
      },
      statusState(row){
            let data
            this.statusOptions.forEach(r=>{
                if (r.value==row.status){
                    data=r.label
                }
            })
            return data
      },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          userid : "", // 用户id
          cardheader : "", // 信用卡头
          ip : "", // ip地址
          contentid : "", // 风控内容id
          remark : "", // 风控等级描述
          type : "", // 名单类别
          status:"",
          endtime : "", // 结束时间(默认永久)
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteRisklist(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelRisklist(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertRisklist(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateRisklist(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getRiskcontentAllInfo() {
          let res = await getRiskcontentAll()
          if (res.status == "200") {
              this.contentList=res.data
          } else {
              MessageError(res.msg)
          }
      },
        async getUserAllInfo() {
            let res = await getUserAll()
            if (res.status == "200") {
                this.userList=res.data
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('risklist:update')&&!this.hasRole('risklist:delete')){
          this.tableConfig.hasOperation=false
        }
        this.getRiskcontentAllInfo()
        this.getUserAllInfo()
      }
    }
  }
</script>

<style scoped>

</style>
